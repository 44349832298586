<script>
  import Input from '../inputs/Input.svelte';

  export let severities;
  export let score = 0;
  export let assignment;
  export let severity;
</script>

<!--
  @component
  List item with checkboxes for each severity.
  
  Usage:
  ```JSX
  <CheckboxScore severities={sevs} score={5} assignment={"Ada.O1.1"} severity={1} />
  ```
-->

<td>
  <span class="assignment">{assignment}</span>
</td>
<td>
  <Input type="number" style="width: 60px" bind:value={score} />
</td>
{#each severities as sev}
  <td>
    <label>
      <input
        class="radio"
        type="radio"
        bind:group={severity.level}
        name={'severity' + assignment}
        value={sev.level}
      />
    </label>
  </td>
{/each}

<style>
  .radio {
    margin-left: 30px;
    height: 32px;
    width: 32px;
  }

  .assignment {
    width: 130px;
  }
</style>
