<script>
  import { activeSeverities } from '../../stores/active_severities';
  import { commentText } from '../../stores/comment_text';
  import { severities } from '../../stores/severities';
  import { chosenComments } from '../../stores/comments';

  export let status;
  export let sum;

  let text;

  $: fillField(status, sum, $activeSeverities, $chosenComments);

  const activeComments = () =>
    $chosenComments.filter((a) => {
      return $activeSeverities[$severities.findIndex((i) => i == a.level)];
    });

  // Formated text according to specifications
  function formatComment(original) {
    const LINE_LENGTH = 50;
    const split = original.split(' ');
    let res = '  ';
    let counter = 0;
    for (const x in split) {
      const word = split[x];
      counter += word.length + 1;
      if (counter < LINE_LENGTH || counter === word.length + 1) {
        res += word + ' ';
      } else {
        counter = 0;
        res += '\n  ' + word + ' ';
      }
    }
    return res;
  }

  function fillField(status, sum) {
    text = 'Bedömning: ';
    if (status === 'pass') {
      text += 'Godkänd\n';
      if (sum <= 0) {
        text += '\n  Utmärkt!\n';
      } else if (sum < 5) {
        text += '\n  Bra jobbat!\n';
      }
    } else {
      text += 'Behöver åtgärdas\n';
    }

    const coms = activeComments().sort((a, b) =>
      a.level < b.level ? 1 : a.level > b.level ? -1 : 0
    );

    let severity = '';
    for (const com of coms) {
      if (severity !== com.severity) {
        severity = com.severity;
        text += '\n' + com.severity + ':\n';
      }
      text += '\n' + formatComment(com.short) + '\n';
    }
    commentText.set(text);
  }
</script>

<!--
  @component
  Formats the chosen comments so they can be copied.

  Usage:
  ```JSX
  <FormatTextArea {status} {sum} />
  ```
-->

<div>
  <textarea id="outputtextarea" disabled>{text}</textarea>
</div>

<style>
  div {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 18px;
    margin-top: 18px;
  }

  textarea {
    overflow: auto;
    resize: none;
    border-radius: 10px;
  }

  #outputtextarea {
    width: 100%;
    flex: 1;
  }
</style>
