<script>
  import { currentAssignment } from '../../stores/current_assignment';
  import { assignments } from '../../stores/assignments';
</script>

<!--
  @component
  Displays list of assignment options.
  
  Usage:
  ```JSX
  <AssignmentList />
  ```
-->

<div id="main-div">
  <ul>
    {#each $assignments as item}
      <li>
        <button
          on:click={() => {
            currentAssignment.set(item);
          }}>{item}</button
        >
      </li>
    {/each}
  </ul>
</div>

<style>
  ul {
    flex: 1;
    list-style-type: none;
    padding-top: 5%;
    padding-left: 0;
    padding-right: 20px;
    margin: 0;
  }

  li {
    margin-top: 0;
    margin-bottom: 0;
  }

  button {
    padding-left: 20px;
    cursor: pointer;
    text-align: start;
    width: 100%;
    margin: 0;
    background-color: #cfffe2;
  }

  #main-div {
    display: flex;
    margin-bottom: 30px;
  }
</style>
