import { historyStore } from '../stores/history_store';
import { chosenComments } from '../stores/comments';

function limitHistory() {
  historyStore.update((value) => {
    if (value.length > 20) {
      value.shift();
    }
    return value;
  });
}

function addChosen(comment) {
  chosenComments.update((value) => {
    if (!value.includes(comment)) {
      value.push(comment);
    }
    return value;
  });
}

function removeChosen(comment) {
  chosenComments.update((value) => {
    return value.filter((c) => c != comment);
  });
}

export function addComment(comment) {
  historyStore.update((value) => {
    const item = {
      type: 'add',
      comment: comment,
    };
    if (!value.includes(item)) {
      value.push(item);
    }
    return value;
  });
  limitHistory();
  addChosen(comment);
}

export function removeComment(comment) {
  historyStore.update((value) => {
    value.push({
      type: 'remove',
      comment: comment,
    });
    return value;
  });
  limitHistory();
  removeChosen(comment);
}

export function undo() {
  historyStore.update((value) => {
    if (value.length > 0) {
      let val = value.pop();
      if (val.type == 'add') {
        removeChosen(val.comment);
      } else {
        addChosen(val.comment);
      }
    }
    return value;
  });
}
