<script>
  import Tooltip, { Wrapper } from '@smui/tooltip';
  import MdHelp from 'svelte-icons/md/MdHelp.svelte';
  import { getContext } from 'svelte';
  import HelpModal from './HelpModal.svelte';

  const { open } = getContext('simple-modal');
  const openHelpModal = () => open(HelpModal, {});
</script>

<!--
  @component
  Opens modal with help information.

  Usage:
  ```JSX
  <HelpModalButton />
  ```
-->

<Wrapper>
  <div class="icon" on:click={openHelpModal}>
    <MdHelp />
  </div>
  <Tooltip>Information om applikationen</Tooltip>
</Wrapper>
