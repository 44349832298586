<script>
  import AssignmentList from './AssignmentList.svelte';
  import ListHeader from '../../layout/ListHeader.svelte';
  import CommentModeButtonRow from '../comment_list/CommentModeButtonRow.svelte';

  export let editMode = false;
</script>

<!--
  @component
  When assignment has not been chosen yet. Displays top bar and list of assignments.
  
  Usage:
  ```JSX
  <AssignmentMode editMode={true}/>
  ```
-->

<ListHeader>
  <div slot="start" />
  <div id="title" slot="text">Uppgiftsspecifika kommentarer</div>
  <div slot="end">
    {#if editMode}
      <CommentModeButtonRow />
    {/if}
  </div>
</ListHeader>
<p>Välj vilken uppgift du vill kolla på</p>
<div class="list-input">
  <AssignmentList />
</div>

<style>
  #title {
    height: 100%;
    margin: auto;
    font-size: 15pt;
  }

  p {
    margin-bottom: 0;
  }

  .list-input {
    flex: 1;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
</style>
