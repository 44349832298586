<script>
  import HelpModalButton from '../help_modal/HelpModalButton.svelte';
  import AddCommentButton from '../small_buttons/AddCommentButton.svelte';
  import Tooltip, { Wrapper } from '@smui/tooltip';
  import MdUndo from 'svelte-icons/md/MdUndo.svelte';
  import MdOpenWith from 'svelte-icons/md/MdOpenWith.svelte';
  import MdRefresh from 'svelte-icons/md/MdRefresh.svelte';
  import { undo } from '../../script/history';

  export let toggleHover = undefined;
  export let reload = undefined;
</script>

<!--
  @component
  Button row with buttons used when grading assignments.

  Usage:
  ```JSX
  <CommentModeButtonRow {undoClick} {toggleHover} {reload} />
  ```
-->

<div class="button-row">
  <HelpModalButton />
  {#if toggleHover}
    <Wrapper>
      <div class="icon" on:click={undo}>
        <MdUndo />
      </div>
      <Tooltip>Ångra senaste (Ctrl+U)</Tooltip>
    </Wrapper>
    <Wrapper>
      <div class="icon" on:click={toggleHover}>
        <MdOpenWith />
      </div>
      <Tooltip>Visa lång beskrivning</Tooltip>
    </Wrapper>
  {/if}
  {#if reload}
    <Wrapper>
      <div
        class="icon"
        on:click={() => {
          reload();
        }}
      >
        <MdRefresh />
      </div>
      <Tooltip>Ladda om kommentarer</Tooltip>
    </Wrapper>
  {/if}
  <Wrapper>
    <div>
      <AddCommentButton editMode={true} />
    </div>
    <Tooltip>Lägg till ny kommentar</Tooltip>
  </Wrapper>
</div>

<style>
  .button-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
</style>
