<script>
  import { keyPressed } from '../../stores/keypressed';
  import CommentCard from './CommentCard.svelte';
  import { onDestroy } from 'svelte';
  import fontColorContrast from 'font-color-contrast';
  import hsl from 'hsl-to-hex';

  export let hover;
  export let list;
  export let activeColumn;
  export let editMode = false;
  export let prompt = '';
  export let color;

  let selected = 0;
  let filteredComments = list.comments;
  const lightness = [66, 76];

  // Iterates through colors so the next category gets the next color
  function getLightness(category) {
    let it = 0;
    let previous = '';
    let ret = 100;
    list.comments.forEach((element) => {
      if (element.category !== previous) {
        previous = element.category;
        if (previous && previous === category) {
          ret = lightness[it];
        }
        it = (it + 1) % lightness.length;
      }
    });
    return ret;
  }

  function getBackgroundColor(category) {
    return hsl(color, 70, getLightness(category));
  }

  function getColor(category) {
    return fontColorContrast(getBackgroundColor(category));
  }

  const unsubscribe = keyPressed.subscribe((value) => {
    if (!value.ctrl) {
      let key = value.key;
      if (key == 'ArrowUp') {
        selected = Math.max(0, selected - 1);
      } else if (key == 'ArrowDown') {
        selected = Math.min(filteredComments.length - 1, selected + 1);
      } else if (key == 'Enter' && activeColumn) {
        prompt = '';
      }
    }
  });

  onDestroy(unsubscribe);

  function match(text, prom) {
    return text.toLowerCase().includes(prom.toLowerCase());
  }

  const filterComments = (comm, prom) => {
    filteredComments = comm.filter(
      (comment) => match(comment.long, prom) || match(comment.short, prom)
    );
  };

  $: filterComments(list.comments, prompt);
</script>

<!--
  @component
  Displays a list of comments of the chosen assignment.

  Usage:
  ```JSX
  <CommentList {hover} {list} {activeColumn} {editMode} {prompt} {color} />
  ```
-->

<div id={activeColumn ? 'main-selected' : 'main-div'}>
  <ul>
    {#each filteredComments as item, i}
      <CommentCard
        item={item}
        hover={hover}
        editMode={editMode}
        enter={selected == i && activeColumn}
        color={selected == i && activeColumn
          ? 'black'
          : getColor(item.category)}
        backgroundColor={selected == i && activeColumn
          ? 'white'
          : getBackgroundColor(item.category)}
        severity={list.severity}
        level={list.level}
      />
    {/each}
  </ul>
</div>

<style>
  ul {
    flex: 1;
    list-style-type: none;
    padding-top: 5%;
    padding-left: 0;
    padding-right: 0;
    margin: 0;
  }

  #main-div {
    min-width: 300px;
    max-width: 400px;
    margin-left: -150px;
  }

  #main-selected {
    min-width: 500px;
    margin-left: -150px;
  }
</style>
