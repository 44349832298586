<script>
  const keybindings = [
    {
      key: 'Ctrl + C',
      text: 'Kopiera nuvarande text som finns i rutan längst ner till höger av skärmen.',
    },
    { key: 'Ctrl + Z', text: 'Ångra senaste kommentaren som lades till.' },
    { key: 'Ctrl + D', text: 'Rensa listan med kommentarer.' },
    { key: 'Ctrl + Backspace', text: 'Ta bort sista ordet i sökfältet.' },
    { key: 'Esc', text: 'Gå tillbaka till listan med uppgifter.' },
    {
      key: 'Fri text',
      text: 'Filtrera kommentarerna med avseende på text. Filtrer använder både den korta och den långa versionen av kommentaren.',
    },
    {
      key: 'Upp/ner-piltangenter',
      text: 'Markera vilken kommentar du vill lägga till ur det filtrerade urvalet.',
    },
    {
      key: 'Vänster/höger-piltangenter',
      text: 'Växla mellan kategorier/nivåer.',
    },
    {
      key: 'Enter',
      text: 'Lägg till den markerade kommentaren till de valda kommentarerna.',
    },
  ];
</script>

<!--
  @component
  Modal containing info about the web application.

  Usage:
  ```JSX
  open(HelpModal, {});
  ```
-->

<div id="main">
  <h1>Användarguide</h1>
  <p>
    Uppcomm är ett verktyg som är till för att förenkla arbetet att rätta
    studenters inlämningar i grundkurser i programmering. När assistenten vill
    rätta en uppgift så kan denne välja uppgiften i fråga och får då upp flera
    listor med kommentarer som kan väljas. Det är kursledare och kursassistenter
    i kursen som är ansvariga för att korrekta kommentarer finns och att dessa
    är graderade korrekt.
  </p>
  <h2>Knappar</h2>
  <p>
    Knapparna i Uppcomm visar beskrivningar när man håller musen på dem. Om inte
    den förklaringen räcker så är det bäst att fråga någon annan assistent. Det
    är okej att testa sig fram på hemsidan också men undvik att lägga till, ta
    bort och redigera kommentarer om inte kursledningen explicit har sagt att
    det är okej. Det är självklart okej att redigera/förtydliga en vald
    kommentar uppe till höger i fönstret eftersom det bara påverkar sin egna
    version av kommentaren. Ta kontakt med en kursassistent eller kursledare om
    du har ett förbättringsförslag för en kommentar eller saknar en kommentar.
  </p>
  <h2>Keybindings</h2>
  <p>
    I Uppcomm finns det tangentkombinationer som kan göra det lättare att rätta.
  </p>
  <table>
    <tr>
      <th> Tangent </th>
      <th> Beskrivning </th>
    </tr>
    {#each keybindings as keybinding}
      <tr>
        <td>{keybinding.key}</td>
        <td>{keybinding.text}</td>
      </tr>
    {/each}
  </table>
</div>

<style>
  table,
  th,
  td {
    border: 1px solid black;
  }

  #main {
    max-width: 900px;
  }
</style>
