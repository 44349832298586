<script>
  import ListHeader from '../../layout/ListHeader.svelte';
  import Spinner from '../Spinner.svelte';
  import SearchBar from '../comment_list/SearchBar.svelte';
  import MdArrowBack from 'svelte-icons/md/MdArrowBack.svelte';
  import { explanationComment } from '../../stores/comments';
  import { currentAssignment } from '../../stores/current_assignment';

  export let comments;

  let prompt = '';

  function back() {
    currentAssignment.set('');
  }
</script>

<!--
  @component
  Lists every comment for the chosen assignment.

  Usage:
  ```JSX
  <CommentsOverview comments={comments} />
  ```
-->

<ListHeader>
  <div slot="start">
    <div class="icon" on:click={back}>
      <MdArrowBack />
    </div>
  </div>
  <div id="assignment" slot="text">
    {$currentAssignment}
  </div>
  <div slot="extra">
    <SearchBar bind:prompt={prompt} />
  </div>
</ListHeader>
{#if comments.length === 0}
  <Spinner />
{:else}
  <div class="lists">
    {#each comments as list}
      <div class="column">
        {list.severity}
        {#each list.comments.filter((item) => item.short
            .toLowerCase()
            .includes(prompt.toLowerCase())) as comment}
          <div
            class="row"
            on:click={() => {
              explanationComment.set(comment);
            }}
          >
            {comment.short}
          </div>
        {/each}
      </div>
    {/each}
  </div>
{/if}

<style>
  #assignment {
    height: 100%;
    margin: auto;
    font-size: 15pt;
  }

  .lists {
    display: flex;
    flex-direction: row;
    padding-right: 20px;
  }

  .column {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 8px 5px;
  }

  .row {
    border: solid 4px;
    border-color: #b1e0ae;
    margin: 5px 0 5px 0;
    padding: 6px;
    border-radius: 5px;
    cursor: pointer;
  }

  .icon {
    padding-right: 10px;
    padding-left: 10px;
  }
</style>
