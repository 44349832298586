<script>
  import Layout from '../../layout/Layout.svelte';
  import Spinner from '../Spinner.svelte';
  import { Svroller } from 'svrollbar';
  import { assignmentComments } from '../../stores/comments';
  import AssignmentMode from '../assignment_list/AssignmentMode.svelte';
  import CommentMode from './CommentMode.svelte';
  import CommentsOverview from '../comment_explanation/CommentsOverview.svelte';
  import { currentAssignment } from '../../stores/current_assignment';
  import { assignments } from '../../stores/assignments';

  export let editMode = false;
  export let getComments;

  let comments = false;

  $: reload($currentAssignment);

  function reload() {
    if (!$currentAssignment) {
      comments = false;
      return;
    }
    getComments($currentAssignment, (value) => {
      value.sort((a, b) => {
        if (a.level > b.level) return -1;
        if (a.level < b.level) return 1;
        return 0;
      });
      value.forEach((severity) => {
        severity.comments.sort((a, b) => {
          const nameA = a.category.toUpperCase(); // ignore upper and lowercase
          const nameB = b.category.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });
      });
      assignmentComments.set(value);
      comments = value;
    });
  }
</script>

<!--
  @component
  View changing between assignment list and comment list.

  Usage:
  ```JSX
  <CompleteList {editMode} {getComments} />
  ```
-->

<Layout>
  {#if !$assignments}
    <Spinner />
  {:else}
    <Svroller width="100%" height="100%">
      {#if comments}
        {#if editMode}
          <CommentMode orgComments={comments} reload={reload} />
        {:else}
          <CommentsOverview comments={comments} />
        {/if}
      {:else}
        <AssignmentMode editMode={editMode} />
      {/if}
    </Svroller>
  {/if}
</Layout>
