// Setup which backend to use
const isLocal =
  location.hostname === 'localhost' || location.hostname === '127.0.0.1';
let url = isLocal
  ? 'https://uppcomm-1-0-devel.upp.ida.liu.se/'
  : 'https://uppcomm.upp.ida.liu.se/';

// Change to localhost backend if it is active
fetch('http://localhost:3000/get_assignments.php')
  .then((res) => {
    if (res.status === 200) {
      console.log('Port 3000 open. Using localhost backend for calls');
      url = 'http://localhost:3000/';
    } else {
      console.log('Port 3000 closed. Using upp server devel for backend');
    }
  })
  .catch(() => {
    console.log('Port 3000 closed. Using upp server devel for backend');
  });

// General functions
function checkValidation(response) {
  if (response.responseText === '') {
    alert('Högre autentiseringsnivå krävs för att komma åt informationen');
  }
}

function getJson(original) {
  return JSON.parse(decodeURIComponent(original));
}

// GET //

function get(scriptName, callback) {
  const ajaxRequest = new XMLHttpRequest();
  ajaxRequest.onload = function () {
    checkValidation(this);
    callback(this.responseText);
  };
  ajaxRequest.open('GET', url + scriptName, true);
  ajaxRequest.send();
}

export function getAssignments(func) {
  // Single ticks are replaces as a python list is not valid json.
  // Dicts are valid so replace is not needed for the other calls
  get('get_assignments.php', (text) =>
    func(getJson(text.replaceAll("'", '"')))
  );
}

export async function getAssignmentComments(assignment, func) {
  get(
    'assistant/get_assignment_comments.php?assignment=' + assignment,
    (text) => func(getJson(text))
  );
}

export async function getAssignmentCommentsLite(assignment, func) {
  get('get_assignment_comments_lite.php?assignment=' + assignment, (text) =>
    func(getJson(text))
  );
}

export async function getComment(commentId, func) {
  get('assistant/get_comment.php?comment_id=' + commentId, (text) =>
    func(getJson(text))
  );
}

export async function getComments(func) {
  get('assistant/get_comments.php', (text) => func(getJson(text)));
}

// POST //

function post(scriptName, data) {
  const ajaxRequest = new XMLHttpRequest();
  ajaxRequest.onload = function () {
    console.log(this.responseText);
  };
  ajaxRequest.open('POST', url + scriptName);
  ajaxRequest.setRequestHeader(
    'Content-Type',
    'application/json;charset=UTF-8'
  );
  ajaxRequest.send(JSON.stringify(data));
}

export function postComment(comment) {
  post('assistant/post_comment.php', comment);
}

export function postAssignment(assignment) {
  post('admin/post_assignment.php', assignment);
}

// PUT //

function put(scriptName, data) {
  const ajaxRequest = new XMLHttpRequest();
  ajaxRequest.onload = function () {
    console.log(this.responseText);
  };
  ajaxRequest.open('PUT', url + scriptName);
  ajaxRequest.setRequestHeader(
    'Content-Type',
    'application/json;charset=UTF-8'
  );
  ajaxRequest.send(JSON.stringify(data));
}

export function putComment(comment) {
  put('assistant/put_comment.php', comment);
}

export function putAssignment(oldAssignment, newAssignment) {
  put('admin/put_assignment.php', [oldAssignment, newAssignment]);
}

// DELETE //

function deleteCall(scriptName) {
  const ajaxRequest = new XMLHttpRequest();
  ajaxRequest.onload = function () {
    console.log(this.responseText);
  };
  ajaxRequest.open('DELETE', url + scriptName);
  ajaxRequest.send();
}

export function deleteComment(commentId) {
  deleteCall('assistant/delete_comment.php?comment_id=' + commentId);
}

export function deleteAssignment(assignment) {
  deleteCall('admin/delete_assignment.php?assignment=' + assignment);
}

export function isAssistant(func) {
  const ajaxRequest = new XMLHttpRequest();
  ajaxRequest.onload = function () {
    func(ajaxRequest.status === 200);
  };
  ajaxRequest.open('GET', url + 'assistant/is_assistant.php');
  ajaxRequest.send();
}

export function isAdmin(func) {
  const ajaxRequest = new XMLHttpRequest();
  ajaxRequest.onload = function () {
    func(ajaxRequest.status === 200);
  };
  ajaxRequest.open('GET', url + 'admin/is_admin.php');
  ajaxRequest.send();
}
