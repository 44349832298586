<!--
  @component
  Area containing slots for navigation, text and buttons.
  Slots:
    - start
    - text
    - middle
    - extra
    - end
  Usage:
  ```JSX
  <ListHeader>
    <div slot="start" />
    <div slot="text" />
  </ListHeader>
  ```
-->

<article class="container">
  <div class="alignment">
    <slot name="start" />
  </div>
  <div class="text">
    <slot name="text" />
  </div>
  <div class="middle">
    <slot name="middle" />
  </div>
  <div class="extra">
    <slot name="extra" />
  </div>
  <div class="alignment right">
    <slot name="end" />
  </div>
</article>

<style>
  .container {
    display: grid;
    padding-top: 20px;
    padding-bottom: 10px;
    align-items: center;
    border-bottom: 1px solid rgb(140, 140, 140);
    margin-right: 10px;
  }

  .alignment {
    grid-column: 1;
  }

  .text {
    grid-column: 2;
  }

  .middle {
    grid-column: 3;
  }

  .extra {
    grid-column: 4;
  }

  .right {
    grid-column: 5;
  }
</style>
