<script>
  import ListHeader from '../../layout/ListHeader.svelte';
  import ColumnSwitch from './ColumnSwitch.svelte';
  import Spinner from '../Spinner.svelte';
  import CommentList from './CommentList.svelte';
  import SearchBar from './SearchBar.svelte';
  import MdArrowBack from 'svelte-icons/md/MdArrowBack.svelte';
  import { keyPressed } from '../../stores/keypressed';
  import CommentModeButtonRow from './CommentModeButtonRow.svelte';
  import { activeSeverities } from '../../stores/active_severities';
  import { severities } from '../../stores/severities';
  import { undo } from '../../script/history';
  import { currentAssignment } from '../../stores/current_assignment';
  import { onDestroy } from 'svelte';

  export let orgComments;
  export let reload;

  let comments = orgComments;
  let activeIndex = 0;
  let hover = false;
  let prompt = '';
  let included;
  const target = Math.floor((orgComments.length - 1) / 2);
  const hslColors = [
    115, // Green
    200, // Blue
    70, // Yellow
    300, // Purple
    15, // Orange
  ];

  $: updatePositioning(activeIndex, orgComments);

  activeSeverities.set(new Array(orgComments.length).fill(true));
  activeSeverities.subscribe((value) => {
    included = value;
    comments = comments;
  });
  severities.set(setSeverities());

  function setSeverities() {
    let sevs = [];
    for (let sev of orgComments) {
      sevs.push(sev.level);
    }
    sevs.sort();
    return sevs;
  }

  function toggleHover() {
    hover = !hover;
  }

  // Rotates columns until the index i is in the center position
  function updatePositioning(i) {
    let carousel = [...orgComments];
    let index = i;
    while (target != index) {
      const elem = carousel.shift();
      carousel.push(elem);
      index = (index + orgComments.length - 1) % orgComments.length;
    }
    comments = carousel;
  }

  function back() {
    currentAssignment.set('');
  }

  const unsubscribe = keyPressed.subscribe((value) => {
    if (value.ctrl) {
      if (value.key == 'z') {
        undo();
      }
    } else {
      if (value.key == 'Escape') {
        back();
      }
    }
  });

  onDestroy(unsubscribe);
</script>

<!--
  @component
  View for displaying comments in a dynamic way.

  Usage:
  ```JSX
  <CommentMode {orgComments} {reload} />
  ```
-->

<ListHeader>
  <div slot="start">
    <div class="icon" on:click={back}>
      <MdArrowBack />
    </div>
  </div>
  <div id="assignment" slot="text">
    {$currentAssignment}
  </div>
  <div slot="middle">
    <ColumnSwitch comments={orgComments} bind:index={activeIndex} />
  </div>
  <div slot="extra">
    <SearchBar bind:prompt={prompt} />
  </div>
  <div slot="end">
    <CommentModeButtonRow toggleHover={toggleHover} reload={reload} />
  </div>
</ListHeader>
{#if comments.length === 0}
  <Spinner />
{:else}
  <div class="lists">
    {#each comments as list, i}
      <div class="column" style="z-index: {5 - Math.abs(i - target)};">
        <div
          class={included[$severities.findIndex((i) => i == list.level)]
            ? 'active-list'
            : 'list-input'}
          style="margin-top: {40 * Math.abs(i - target)}px;"
        >
          <CommentList
            list={list}
            editMode={true}
            hover={hover}
            color={hslColors[$severities.findIndex((i) => i == list.level)]}
            bind:prompt={prompt}
            activeColumn={i == target}
          />
        </div>
      </div>
    {/each}
  </div>
{/if}

<style>
  #assignment {
    height: 100%;
    margin: auto;
    font-size: 15pt;
  }

  .lists {
    display: flex;
    flex-direction: row;
    padding-left: 70px;
    overflow-x: hidden;
  }

  .active-list {
    flex: 1;
    height: min-content;
  }

  .list-input {
    filter: grayscale(60%);
    flex: 3;
    height: min-content;
  }

  .icon {
    padding-right: 10px;
    padding-left: 10px;
  }

  .column {
    margin-top: 8px;
    height: max-content;
    margin-bottom: 30px;
  }
</style>
