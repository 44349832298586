<script>
  import { explanationComment } from '../../stores/comments';
</script>

<!--
  @component
  Displays short and long versions of the latest chosen comment.

  Usage:
  ```JSX
  <ExplanationText />
  ```
-->

<div class="container">
  {#if $explanationComment}
    <h2>Kort beskrivning</h2>
    <p>{$explanationComment.short}</p>
    <h2>Förklaring</h2>
    <p>{$explanationComment.long}</p>
  {:else}
    <h2>Välj en kommentar till vänster</h2>
  {/if}
</div>

<style>
  p {
    white-space: pre-wrap;
  }

  .container {
    padding-left: 28px;
    padding-top: 10px;
    padding-right: 28px;
  }
</style>
