<script>
  import CompleteList from '../components/comment_list/CompleteList.svelte';
  import OutputArea from '../components/grading/OutputArea.svelte';
  import { getAssignmentComments } from '../script/database';

  let assignment;
</script>

<!--
  @component
  Page used to correct student submissions.
  Usage:
  ```JSX
  <Correct />
  ```
-->

<div id="container">
  <div id="left-div">
    <CompleteList
      editMode={true}
      getComments={getAssignmentComments}
      bind:assignment={assignment}
    />
  </div>
  <div id="right-div">
    <OutputArea />
  </div>
</div>

<style>
  #container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
  }

  #left-div {
    display: flex;
    flex: 1;
  }

  #right-div {
    padding: 20px;
  }
</style>
