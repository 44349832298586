<script>
  import { RingLoader } from 'svelte-loading-spinners';
</script>

<!--
  @component
  Animated spinner that can be used during loading.
  
  Usage:
  ```JSX
  <Spinner />
  ```
-->

<div class="div">
  <RingLoader size="160" color="#a2d29e" />
</div>

<style>
  .div {
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
