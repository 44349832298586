<script>
  import { getEventsAction } from '../../script/utils';
  import { current_component } from 'svelte/internal';

  const events = getEventsAction(current_component);
  export let value = '';
  export let type;
  export let style = '';
</script>

<!--
  @component
  Input component that handles event actions. The following types are available:
  - text
  - number
  - textarea
  
  Usage:
  ```JSX
  <GeneralInput type={"text"} style={style} bind:value={value} />
  ```
-->

{#if type == 'text'}
  <input use:events style={style} type="text" bind:value={value} />
{:else if type == 'number'}
  <input use:events style={style} type="number" bind:value={value} />
{:else if type == 'textarea'}
  <textarea use:events bind:value={value} />
{:else}
  <div>Invalid type for Input</div>
{/if}

<style>
  textarea {
    border-width: 0;
    margin-bottom: 20px;
    min-height: 300px;
    width: 400px;
  }
</style>
