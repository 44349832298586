<script>
  import FormatTextArea from './FormatTextArea.svelte';
  import SortedCommentList from './SortedCommentList.svelte';
  import { Svroller } from 'svrollbar';
  import { chosenComments } from '../../stores/comments';
  import GradingButtons from './GradingButtons.svelte';
  import { activeSeverities } from '../../stores/active_severities';
  import { severities } from '../../stores/severities';
  import QuickAdd from './QuickAdd.svelte';
  import { currentAssignment } from '../../stores/current_assignment';

  const grades = {
    pass: 'pass',
    fail: 'fail',
  };

  let sum = 0;
  let status;

  // If values change then the functions are called
  $: updateGrade($currentAssignment, $activeSeverities, $chosenComments);

  function findScore(com) {
    if (
      com.score &&
      $activeSeverities[$severities.findIndex((i) => i == com.level)]
    ) {
      return com.score;
    }
    return 0;
  }

  // Changes grade if score surpasses 10
  function updateGrade() {
    sum = $chosenComments.reduce(
      (prev, curr) => prev + parseInt(findScore(curr)),
      0
    );
    if (sum < 10) {
      status = grades.pass;
    } else {
      status = grades.fail;
    }
  }
</script>

<!--
  @component
  Area containing formatted text and buttons which calculates points.

  Usage:
  ```JSX
  <OutputArea />
  ```
-->

<div id="main-div">
  <div id="flex-area">
    <div class="big-area">
      <Svroller width="100%" height="100%">
        <div id="list">
          <span>Kommentarer </span>
          <QuickAdd />
          <br />
          <SortedCommentList update={updateGrade} />
        </div>
      </Svroller>
    </div>
    <div class="small-area">
      <FormatTextArea status={status} sum={sum} />
      <GradingButtons
        sum={sum}
        pass={() => {
          status = grades.pass;
        }}
        fail={() => {
          status = grades.fail;
        }}
      />
    </div>
  </div>
</div>

<style>
  span {
    margin: 10px;
    margin-right: 105px;
  }

  .big-area {
    height: 60%;
  }

  .small-area {
    height: 40%;
    display: flex;
    width: 400px;
    bottom: 0;
  }

  #main-div {
    height: 100%;
    width: min-content;
  }

  #flex-area {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
</style>
