<script>
  import Tooltip, { Wrapper } from '@smui/tooltip';
  import { keyPressed } from '../../stores/keypressed';
  import MdKeyboardArrowLeft from 'svelte-icons/md/MdKeyboardArrowLeft.svelte';
  import MdKeyboardArrowRight from 'svelte-icons/md/MdKeyboardArrowRight.svelte';

  export let comments;
  export let index = 0;

  function modAdd(a, b) {
    const m = comments.length;
    return (((a + b) % m) + m) % m;
  }

  function update(change) {
    index = modAdd(index, change);
  }

  // Arrow keys rotate columns
  keyPressed.subscribe((value) => {
    if (value.key == 'ArrowRight') {
      update(1);
    } else if (value.key == 'ArrowLeft') {
      update(-1);
    }
  });
</script>

<!--
  @component
  Buttons for switching between comment columns.

  Usage:
  ```JSX
  <ColumnSwitch {comments} bind:index={activeIndex} />
  ```
-->

<div id="main-div">
  <Wrapper>
    <div class="icon" on:click={() => update(-1)}>
      <MdKeyboardArrowLeft />
    </div>
    <Tooltip>Föregående grad</Tooltip>
  </Wrapper>
  <div id="text">
    {comments[index].severity}
  </div>
  <Wrapper>
    <div class="icon" on:click={() => update(1)}>
      <MdKeyboardArrowRight />
    </div>
    <Tooltip>Nästa grad</Tooltip>
  </Wrapper>
</div>

<style>
  #main-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  #text {
    width: 150px;
    font-size: 12.5pt;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
