<script>
  import Hoverable from '../../layout/Hoverable.svelte';
  import { deleteComment, putComment } from '../../script/database.js';
  import { chosenComments } from '../../stores/comments';
  import { keyPressed } from '../../stores/keypressed';
  import Popup from '../edit_comment_popup/Popup.svelte';
  import { getContext, onDestroy } from 'svelte';
  import { addComment, removeComment } from '../../script/history';

  export let item;
  export let severity;
  export let level;
  export let hover;
  export let editMode = false;
  export let enter = false;
  export let color;
  export let backgroundColor;

  let changeItem = '';
  let chosen = false;
  let element;

  item['severity'] = severity;
  item['level'] = level;

  $: scrollVisible(enter);
  $: updateChosen(item, chosen, $chosenComments);

  function scrollVisible(enter) {
    if (element && enter) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  function updateChosen() {
    chosen = $chosenComments.includes(item);
  }

  const unsubscribe = keyPressed.subscribe((value) => {
    if (!value.ctrl) {
      if (value.key == 'Enter' && enter) {
        toggleComment();
      }
    }
  });

  onDestroy(unsubscribe);

  function toggleComment() {
    if (chosen) {
      removeComment(item);
    } else {
      addComment(item);
    }
    chosen = !chosen;
  }

  // Modal lambda functions
  const onSubmit = (comment) => {
    putComment(comment);
  };

  const onDelete = (commentId) => {
    deleteComment(commentId);
  };

  const { open } = getContext('simple-modal');
  const showModal = () =>
    open(Popup, {
      value: changeItem,
      onSubmit: onSubmit,
      onDelete: onDelete,
    });
</script>

<!--
  @component
  Clickable card for displaying a comment.

  Usage:
  ```JSX
  <CommentCard {item} {severity} {level} {hover} {editMode} {enter} {color} {backgroundColor} />
  ```
-->

<Hoverable let:hovering={active}>
  <li bind:this={element}>
    <button
      class="add-button"
      on:click={() => {
        toggleComment();
      }}
      style="
        color: {chosen ? 'white' : enter ? 'black' : color};
        background-color: {chosen ? 'black' : enter ? 'white' : backgroundColor}
      "
    >
      {#if editMode}
        <div class="score">
          <span class="score-span">{item.score}</span>
        </div>
      {/if}
      <div class="text">
        {active && hover ? item.long : item.short}
      </div>
    </button>
    {#if editMode}
      <button
        on:click={() => {
          changeItem = item;
          showModal();
        }}
        class="menu-button"
      />
    {/if}
  </li>
</Hoverable>

<style>
  li {
    display: flex;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
  }

  button {
    margin-bottom: 0;
    cursor: pointer;
  }

  .text {
    padding-left: 10px;
    padding-right: 6px;
  }

  .score {
    padding-left: 6px;
    padding-right: 10px;
    border-right-width: 1px;
    border-right-style: solid;
    font-size: large;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .score-span {
    text-align: center;
    width: 20px;
  }

  .add-button {
    flex: 1;
    display: flex;
    flex-direction: row;
    text-align: start;
    margin: 0;
    background-color: #cfffe2;
  }

  .menu-button {
    width: 30px;
  }
</style>
