<script>
  import Correct from './page/Correct.svelte';
  import Explanation from './page/Explanation.svelte';
  import { isAdmin, isAssistant } from './script/database';
  import { keyDown, keyUp } from './script/keyboard';
  import { getAssignments } from './script/database.js';
  import { assignments } from './stores/assignments';
  import Admin from './page/Admin.svelte';

  let pages = [{ name: 'Förklaring', component: Explanation }];

  setTimeout(() => {
    isAssistant((allowed) => {
      if (allowed) {
        pages.push({ name: 'Rätta', component: Correct });
        pages = pages;
      }
    });
    isAdmin((allowed) => {
      if (allowed) {
        pages.push({ name: 'Admin', component: Admin });
        pages = pages;
      }
    });
    getAssignments((value) => {
      assignments.set(value);
    });
  }, 500);

  let page;
</script>

<!--
  @component
  Shows the start page with two options if user is admin.
  
  Usage:
  ```JSX
  <Content />
  ```
-->

<main>
  {#if page}
    <svelte:component this={page.component} />
  {:else}
    <div class="container">
      {#each pages as option}
        <div
          class="option"
          on:click={() => {
            page = option;
          }}
        >
          <div class="text">
            {option.name}
          </div>
        </div>
      {/each}
    </div>
  {/if}
</main>

<svelte:window on:keydown={keyDown} on:keyup={keyUp} />

<style>
  main {
    display: flex;
    align-items: stretch;
    height: 100%;
    width: 100%;
    margin: 0;
    background-color: #a2d29e;
  }

  .container {
    width: 100%;
    padding: 100px 100px 100px 0;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    background-color: aliceblue;
    height: 100%;
    margin-left: 100px;
    border-radius: 30px;
    border-style: solid;
    border-width: 6px;
  }

  .text {
    font-size: 70px;
  }
</style>
