<script>
  import CompleteList from '../components/comment_list/CompleteList.svelte';
  import ExplanationText from '../components/comment_explanation/ExplanationText.svelte';
  import { getAssignmentCommentsLite } from '../script/database';
</script>

<!--
  @component
  Page containing explanations of all the possible comments a student can recieve.
  Usage:
  ```JSX
    <Explanation />
  ```
-->

<div class="comments">
  <CompleteList editMode={false} getComments={getAssignmentCommentsLite} />
</div>
<div class="explanation">
  <ExplanationText />
</div>

<style>
  .explanation {
    flex: 1;
  }

  .comments {
    flex: 3;
    display: flex;
  }
</style>
