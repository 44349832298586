<script>
  import {
    disableInputBehavior,
    enableInputBehavior,
  } from '../../script/keyboard';
  import { assignmentComments, chosenComments } from '../../stores/comments';
  import { addComment } from '../../script/history';

  let allComments;
  let comments;
  let active = false;

  assignmentComments.subscribe((value) => {
    allComments = value;
  });

  chosenComments.subscribe((value) => {
    comments = value;
  });

  function addMode() {
    enableInputBehavior();
    active = true;
  }

  // Finds comment with matching comment_id
  function findComment(id) {
    for (const severity of allComments) {
      for (const comment of severity.comments) {
        if (comment.metadata.comment_id == id) {
          let res = comment;
          res.severity = severity.severity;
          res.level = severity.level;
          return res;
        }
      }
    }
    return undefined;
  }

  // Adds comments in QuickAdd if user presses Enter
  function onKeyDown(event) {
    // If Enter is pressed
    let quickAddId = event.target.value;
    let list = quickAddId.split(' ');
    for (const id of list) {
      // Checks every value. Only adds if comment_id exists
      if (!isNaN(id)) {
        let comment = findComment(parseInt(id));
        if (comment && !comments.includes(comment)) {
          addComment(comment);
        }
      }
    }
    event.target.value = '';
    active = false;
    disableInputBehavior();
  }
</script>

<!--
  @component
  QuickAdd serves as an input field to add comments through comment id.

  Usage:
  ```JSX
  <QuickAdd />
  ```
-->

{#if active}
  <input
    name="quick-add"
    placeholder="43 27 12 67"
    type="text"
    on:change={(event) => onKeyDown(event)}
  />
{:else}
  <button
    on:click={() => {
      addMode();
    }}
  >
    Quick Add
  </button>
{/if}
