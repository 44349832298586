<script>
  import MdAddBox from 'svelte-icons/md/MdAddBox.svelte';
  import Popup from '../edit_comment_popup/Popup.svelte';
  import { postComment } from '../../script/database.js';
  import { getContext } from 'svelte';
  const { open } = getContext('simple-modal');

  export let editMode = false;

  // Modal to add new comment
  function openModal() {
    open(Popup, {
      onSubmit: postComment,
      onDelete: undefined,
      value: undefined,
    });
  }
</script>

<!--
  @component
  Button to open modal to add new comment.

  Usage:
  ```JSX
  <AddCommentButton editMode={true} />
  ```
-->

{#if editMode}
  <div class="icon" on:click={openModal}>
    <MdAddBox />
  </div>
{/if}
