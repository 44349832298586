import { keyPressed } from '../stores/keypressed';

// Functions for when keys are pressed and released.
// Updates the keyPressed store so files can subscribe to
// get the lastest key pressed.

let isStandardBehavior = false;

export function keyDown(event) {
  if (!isStandardBehavior) {
    event.preventDefault();
    let key = event.key;
    if (key == 'Control') {
      keyPressed.update((old) => {
        old['ctrl'] = true;
        return old;
      });
    } else {
      keyPressed.update((old) => {
        old['key'] = key;
        return old;
      });
    }
  }
}

export function keyUp(event) {
  if (!isStandardBehavior) {
    event.preventDefault();
    let key = event.key;
    if (key == 'Control') {
      keyPressed.update((old) => {
        old['ctrl'] = false;
        return old;
      });
    }
    // To avoid ctrl + KEY, release ctrl => new press KEY
    keyPressed.update((old) => {
      old['key'] = '';
      return old;
    });
  }
}

export function enableInputBehavior() {
  isStandardBehavior = true;
}

export function disableInputBehavior() {
  isStandardBehavior = false;
}
