<!--
  @component
  Layout and styling for the complete list.
  Usage:
  ```JSX
  <Layout>
    <div />
  </Layout>
  ```
-->

<div class="container">
  <slot />
</div>

<style>
  .container {
    flex: 1;
    margin: 10px;
    padding-left: 20px;
    padding-right: 0;
    background-color: azure;
    border: groove 2px;
    border-radius: 12px;
    overflow: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    scroll-margin-top: 20px;
  }
</style>
