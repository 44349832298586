<script>
  import { keyPressed } from '../../stores/keypressed';

  export let prompt = '';

  keyPressed.subscribe((value) => {
    let key = value.key;
    if (value.ctrl) {
      if (key == 'Backspace') {
        const line = prompt.split(' ');
        if (line.length < 2) {
          prompt = '';
        } else {
          const n = line.length;
          prompt = prompt.slice(0, prompt.length - line[n - 1].length - 1);
        }
      }
    } else {
      if (key == 'Backspace') {
        prompt = prompt.slice(0, prompt.length - 1);
      } else if (key.length == 1) {
        prompt += key;
      }
    }
  });
</script>

<!--
  @component
  SearchBar exporting the written prompt.

  Usage:
  ```JSX
  <SearchBar bind:prompt />
  ```
-->

<input type="text" placeholder="Sök" value={prompt} />

<style>
  input {
    margin-left: 10px;
    height: 30px;
  }
</style>
