<script>
  let hovering;

  function enter() {
    hovering = true;
  }

  function leave() {
    hovering = false;
  }
</script>

<!--
  @component
  Component wrapper that checks if the mouse is hovering over the component.
	
  Usage:
  ```JSX
  <Hoverable let:hovering={variable}>
    <ComponentUsingHover hover={variable} />
	</Hoverable>
  ```
-->

<div on:mouseenter={enter} on:mouseleave={leave}>
  <slot hovering={hovering} />
</div>
