<script>
  import { keyPressed } from '../../stores/keypressed';
  import Tooltip, { Wrapper } from '@smui/tooltip';
  import MdContentCopy from 'svelte-icons/md/MdContentCopy.svelte';
  import { commentText } from '../../stores/comment_text';

  let text = '';

  // Might not work on all browsers
  function copyToClip() {
    navigator.clipboard.writeText(text);
  }

  keyPressed.subscribe((value) => {
    if (value.ctrl && value.key == 'c') {
      copyToClip();
    }
  });

  commentText.subscribe((value) => {
    text = value;
  });
</script>

<!--
  @component
  Button for copying the current content of the output area.

  Usage:
  ```JSX
  <CopyButton />
  ```
-->

<Wrapper>
  <div id="copy-button" class="icon" on:click={copyToClip}>
    <MdContentCopy />
  </div>
  <Tooltip>Kopiera texten (Ctrl+C)</Tooltip>
</Wrapper>

<style>
  #copy-button {
    border-radius: 8px;
    color: white;
    background-color: steelblue;
    padding: 8px;
  }
</style>
