<script>
  import Tooltip, { Wrapper } from '@smui/tooltip';
  import { chosenComments, assignmentComments } from '../../stores/comments';
  import { activeSeverities } from '../../stores/active_severities';
  import FaThumbsUp from 'svelte-icons/fa/FaThumbsUp.svelte';
  import FaThumbsDown from 'svelte-icons/fa/FaThumbsDown.svelte';
  import FaTrashAlt from 'svelte-icons/fa/FaTrashAlt.svelte';
  import CopyButton from './CopyButton.svelte';
  import { historyStore } from '../../stores/history_store';
  import { keyPressed } from '../../stores/keypressed';

  export let sum;
  export let pass;
  export let fail;

  // Clears textarea and resets values
  function clearComments() {
    chosenComments.set([]);
    historyStore.set([]);
    activeSeverities.set(new Array($assignmentComments.length).fill(true));
  }

  keyPressed.subscribe((value) => {
    if (value.ctrl && value.key == 'd') {
      clearComments();
    }
  });
</script>

<!--
  @component
  Column with buttons used for grading the assignment.

  Usage:
  ```JSX
  <GradingButtons {sum} {pass} {fail} />
  ```
-->

<div class="button-col">
  <span id="score" class="icon">{sum}</span>
  <Wrapper>
    <div
      id="pass"
      class="icon"
      on:click={() => {
        pass();
      }}
    >
      <FaThumbsUp />
    </div>
    <Tooltip>Sätt Godkänt</Tooltip>
  </Wrapper>

  <Wrapper>
    <div
      id="fail"
      class="icon"
      on:click={() => {
        fail();
      }}
    >
      <FaThumbsDown />
    </div>
    <Tooltip>Sätt komplettering</Tooltip>
  </Wrapper>

  <Wrapper>
    <div id="delete" class="icon" on:click={clearComments}>
      <FaTrashAlt />
    </div>
    <Tooltip>Rensa valda kommentarer</Tooltip>
  </Wrapper>

  <CopyButton />
</div>

<style>
  .button-col {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .icon {
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 8px;
  }

  #score {
    margin-bottom: 10px;
    padding: 0;
    align-self: center;
    text-align: center;
    font-size: 24pt;
  }

  #pass {
    background-color: #569956;
    color: white;
    transform: rotateY(180deg);
  }

  #fail {
    color: white;
    background-color: #e87d49;
  }

  #delete {
    background-color: lightgrey;
  }
</style>
