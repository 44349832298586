<script>
  import FaAngleUp from 'svelte-icons/fa/FaAngleUp.svelte';
  import FaAngleDown from 'svelte-icons/fa/FaAngleDown.svelte';
  import FaTrashAlt from 'svelte-icons/fa/FaTrashAlt.svelte';
  import { chosenComments, assignmentComments } from '../../stores/comments';
  import { activeSeverities } from '../../stores/active_severities';
  import { severities } from '../../stores/severities';
  import { removeComment } from '../../script/history';
  import Input from '../inputs/Input.svelte';

  let comments = [];
  export let update;

  chosenComments.subscribe((value) => {
    comments = value;
  });

  // Swap comments in an array
  function swap(index, direction) {
    if (comments[index + direction].level == comments[index].level) {
      const temp = comments[index + direction];
      comments[index + direction] = comments[index];
      comments[index] = temp;
    }
    chosenComments.set(comments);
  }

  function move(comment, down) {
    if (comments.length > 1) {
      for (let i = 1 - down; i < comments.length - down; i++) {
        if (comments[i].short === comment.short) {
          swap(i, down * 2 - 1);
          return;
        }
      }
    }
  }

  function moveUp(comment) {
    move(comment, 0);
  }

  function moveDown(comment) {
    move(comment, 1);
  }

  function remove(comment) {
    removeComment(comment);
    update();
  }
</script>

<!--
  @component
  List of chosen comments allowing the used to adjust order and delete unwanted comments.

  Usage:
  ```JSX
  <SortedCommentList {update} />
  ```
-->

<div id="main-div">
  {#each $assignmentComments.sort((a, b) => a.level < b.level) as severity}
    {#if comments.filter((a) => a.level == severity.level).length > 0}
      <div>
        <input
          type="checkbox"
          checked={$activeSeverities[
            $severities.findIndex((i) => i == severity.level)
          ]}
          on:change={(b) => {
            activeSeverities.update((old) => {
              old[$severities.findIndex((i) => i == severity.level)] =
                b.target.checked;
              return old;
            });
          }}
        />
        <span>{severity.severity}</span>
      </div>
    {/if}
    {#each comments.filter((a) => a.level == severity.level) as com}
      <div id="card">
        <div id="sorting-buttons">
          <div
            class="icon up-down"
            on:click={() => {
              moveUp(com);
            }}
          >
            <FaAngleUp />
          </div>
          <div
            class="icon up-down"
            on:click={() => {
              moveDown(com);
            }}
          >
            <FaAngleDown />
          </div>
        </div>
        <Input type="text" bind:value={com.short} />
        <div
          id="trash"
          class="icon"
          on:click={() => {
            remove(com);
          }}
        >
          <FaTrashAlt />
        </div>
      </div>
    {/each}
  {/each}
</div>

<style>
  #main-div {
    overflow-y: scroll;
  }

  .up-down {
    padding: 0;
    width: 10px;
    height: 10px;
    flex: 1;
  }

  #trash {
    margin-left: 8px;
    margin-right: 12px;
    height: 24px;
    width: 24px;
    color: crimson;
  }

  #card {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  #sorting-buttons {
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
</style>
