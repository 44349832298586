<script>
  import { getContext } from 'svelte';
  import {
    getAssignmentComments,
    getAssignments,
    getComment,
  } from '../../script/database';
  import CheckboxScore from './CheckboxScore.svelte';
  import Input from '../inputs/Input.svelte';

  // Context from App.svelte
  const { close } = getContext('simple-modal');
  const inactive = 10;
  const undefinedText = 'Odefinierad';

  export let value;
  export let onSubmit;
  export let onDelete;

  let assignments = [];
  // To save info required for the right list
  let assignmentInfo;
  let comment = {
    short: '',
    long: '',
    category: '',
    program: '',
  };
  let program = '';
  let programActive = false;
  let severities;

  getAssignments((text) => {
    assignments = text;
    getAssignmentComments(assignments[0], (value) => {
      const temp = [{ name: undefinedText, level: inactive }];
      for (const sev of value) {
        temp.push({ name: sev.severity, level: sev.level });
      }
      severities = temp;
    });
    if (value) {
      getComment(value.metadata.comment_id, (text) => {
        comment = text;
        if (comment.program) {
          program = comment.program;
          programActive = true;
        }
        createStruct(text);
      });
    } else {
      createStruct({ scores: [] });
    }
  });

  function createStruct(val) {
    assignmentInfo = {};
    for (const assignment of assignments) {
      // General score blocks specific scores
      const foundItem = val.scores.find((obj) => obj.assignment === assignment);
      assignmentInfo[assignment] =
        foundItem === undefined
          ? {
              severity: { name: undefinedText, level: inactive },
              score: 0,
            }
          : {
              severity: foundItem.severity,
              score: foundItem.score,
            };
    }
  }

  function send() {
    const scores = [];
    let noChoice = true;
    for (const assignment of assignments) {
      if (assignmentInfo[assignment].severity.level !== inactive) {
        scores.push({
          assignment: assignment,
          score: assignmentInfo[assignment].score,
          severity: assignmentInfo[assignment].severity,
        });
        noChoice = false;
      }
    }
    if (noChoice) {
      alert('Välj en uppgift innan du skickar');
      return;
    }
    // Changes the scores based on input
    comment.scores = scores;
    comment.program = programActive ? program : '';
    onSubmit(comment);
    close();
  }

  function deleteCom() {
    if (confirm('Är du säker på att du vill ta bort den här kommentaren?')) {
      onDelete(value.metadata.comment_id);
      close();
    }
  }
</script>

<!--
  @component
  Popup used to edit and add new comments.
  
  Usage:
  ```JSX
  open(Popup, {
    value: comment,
    onSubmit: onSubmit,
    onDelete: onDelete,
  });
  ```
-->

<div id="main">
  <div id="left">
    <div class="input-div">
      <span class="descriptor">Kort version</span>
      <br />
      <Input type="text" bind:value={comment.short} />
    </div>
    <span class="descriptor">Lång version</span>
    <Input type="textarea" bind:value={comment.long} />
    <div class="input-div">
      <span class="descriptor">Kategori</span>
      <br />
      <Input type="text" bind:value={comment.category} />
    </div>
    <div class="input-div">
      <span class="descriptor">Testprogramsanrop</span>
      <br />
      <input type="checkbox" bind:checked={programActive} />
      {#if programActive}
        <Input type="text" bind:value={program} />
      {:else}
        <p>
          aktivera endast om denna kommentar har ett motsvarande testprogram
        </p>
      {/if}
    </div>
    <div id="flex-buttons">
      <button on:click={send}>Skicka</button>
      {#if onDelete !== undefined}
        <button on:click={deleteCom}>Radera från databas</button>
      {/if}
    </div>
  </div>
  <div id="right">
    <table>
      {#if assignmentInfo && severities}
        <tr>
          <th>Uppgift</th>
          <th>Poäng</th>
          {#each severities as severity}
            <th>{severity.name}</th>
          {/each}
        </tr>
        {#each assignments as assignment}
          <tr>
            <CheckboxScore
              severities={severities}
              assignment={assignment}
              bind:score={assignmentInfo[assignment].score}
              bind:severity={assignmentInfo[assignment].severity}
            />
          </tr>
        {/each}
      {/if}
    </table>
  </div>
</div>

<style>
  table {
    margin-left: 10px;
  }

  input {
    padding: 8px;
    padding-top: 0;
    margin-top: 0;
    width: 100%;
    border-width: 0;
  }

  .input-div {
    margin-right: 10px;
    margin-bottom: 30px;
  }

  .descriptor {
    width: max-content;
    padding: 8px;
    background-color: white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  #main {
    width: 90%;
    background-color: #a2d29e;
    display: flex;
    flex-direction: row;
  }

  #left {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  #right {
    width: 700px;
  }

  #flex-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
</style>
