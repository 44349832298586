<script>
  import { getContext } from 'svelte';
  import AssignmentList from '../components/assignment_list/AssignmentList.svelte';
  import Popup from '../components/edit_comment_popup/Popup.svelte';
  import { currentAssignment } from '../stores/current_assignment';
  import { allComments } from '../stores/comments';
  import {
    putAssignment,
    postAssignment,
    deleteAssignment,
    getComments,
    putComment,
    deleteComment,
  } from '../script/database.js';
  import { enableInputBehavior } from '../script/keyboard.js';
  import { onMount } from 'svelte';

  let assignment = '';

  onMount(() => {
    enableInputBehavior();

    currentAssignment.subscribe((value) => {
      assignment = value;
    });

    getComments((comments) => {
      allComments.set(comments);
    });
  });

  function onSave() {
    if ($currentAssignment) {
      putAssignment($currentAssignment, assignment);
    } else {
      postAssignment(assignment);
    }
  }

  // Modal lambda functions
  const onSubmit = (comment) => {
    putComment(comment);
  };

  const onDelete = (commentId) => {
    deleteComment(commentId);
  };

  const { open } = getContext('simple-modal');
  const showModal = (comment) =>
    open(Popup, {
      value: comment,
      onSubmit: onSubmit,
      onDelete: onDelete,
    });
</script>

<!--
  @component
  Page used administration of assignments.
  Usage:
  ```JSX
  <Admin />
  ```
-->

<div id="container">
  <div id="assignment-list">
    <h2>Uppgifter</h2>
    <AssignmentList />
  </div>
  <div id="current-assignment">
    <h2>
      {$currentAssignment
        ? 'Ändrar på ' + $currentAssignment
        : 'Skapar en ny uppgift'}
    </h2>
    <label>
      Uppgiftsnamn
      <input type="text" bind:value={assignment} />
    </label>
    <button on:click={onSave}>Spara uppgift</button>
    <button on:click={() => deleteAssignment(assignment)}
      >Ta bort uppgift</button
    >
    {#if $currentAssignment}
      <br />
      <button on:click={() => currentAssignment.set('')}
        >Byt till att skapa en ny uppgift</button
      >
    {/if}
  </div>
  <div id="all-comments">
    <h2>Alla kommentarer</h2>
    {#each $allComments as comment}
      <button class="add-button" on:click={() => showModal(comment)}>
        <div class="text">
          {comment.short}
        </div>
      </button>
    {/each}
  </div>
</div>

<style>
  button {
    margin-top: 8px;
  }

  #container {
    display: flex;
    flex-direction: row;
    padding: 40px;
  }

  #assignment-list {
    overflow-y: scroll;
    width: 300px;
  }

  #current-assignment {
    padding-left: 20px;
    padding-right: 20px;
    gap: 8px;
  }

  #all-comments {
    padding-left: 20px;
    padding-right: 20px;
    overflow-y: scroll;
  }

  .add-button {
    flex: 1;
    display: flex;
    flex-direction: row;
    text-align: start;
    margin: 0;
    background-color: #cfffe2;
    min-width: 300px;
  }

  .text {
    padding-left: 10px;
    padding-right: 6px;
  }
</style>
