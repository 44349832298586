<script>
  import {
    disableInputBehavior,
    enableInputBehavior,
  } from '../../script/keyboard';
  import GeneralInput from './GeneralInput.svelte';

  export let value = '';
  export let type;
  export let style = '';
</script>

<!--
  @component
  Input component that enables normal input behavior. The following types are available:
  - text
  - number
  - textarea
  
  Usage:
  ```JSX
  <Input type={"text"} style={style} bind:value={value} />
  ```
-->

<GeneralInput
  type={type}
  style={style}
  on:focus={() => enableInputBehavior()}
  on:blur={disableInputBehavior}
  bind:value={value}
/>
