<script>
  import Content from './Content.svelte';
  import Modal from 'svelte-simple-modal';
</script>

<!--
  @component
  Top component containing the modal.
	
  Usage:
  ```JSX
  <App />
  ```
-->

<Modal styleWindow={{ backgroundColor: '#a2d29e', width: 'max-content' }}>
  <Content />
</Modal>
